// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations
import enTranslation from '../locales/en.json';
import frTranslation from '../locales/fr.json';
import nlTranslation from '../locales/nl.json';

const resources = {
    fr: {
        translation: frTranslation,
    },
    nl: {
        translation: nlTranslation,
    },
};

const storedLanguage = localStorage.getItem('language');

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: storedLanguage || 'nl', // Use stored language or default to 'nl'
        fallbackLng: 'nl',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;

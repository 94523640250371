import React, {useState, useEffect} from "react"

import UserService from '../../services/user.service'
import AuthService from "../../services/auth.service";

import DefaultLayout from "../../layout/defaultLayout";
import {useNavigate} from "react-router-dom";
import Papa from "papaparse";
import importService from "../../services/import.service";

export default function Import(props) {
    const navigate = useNavigate();
    const [state, setState] = useState({})
    const [message, setMessage] = useState("")
    const [file, setFile] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [containsEmail, setContainsEmail] = useState(false)

    let fileReader;

    const handleFileRead = async (e) => {
        const content = fileReader.result;
        //console.log(content)
        setDisabled(true)
        setMessage("Importing...")

        const result = await UserService.import(content)

        setMessage("done")
        setDisabled(false)
    };

    const handleFileChosen = (file) => {
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
    };
    
    useEffect(() => {
        console.log(containsEmail)
    }, [containsEmail])



    // Function to handle the parsed data
    const handleCSVData = (result) => {
        // Access the data and set it to the state
        console.log(result)
        importService.importUsers(result, containsEmail, true, true)
    };

    // Function to handle file input change
    const handleFileChange = (event) => {
        const file = event.target.files[0];

        // Parse the CSV file
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: handleCSVData,
        });
    };

    useEffect(() => {

        let user = AuthService.getCurrentUser()

        if (!user) {
            navigate("/login")
        }

    }, [])

    return (
        <DefaultLayout>
            <div className="pagina-gebruikers d-flex flex-column mb-4">
                <div className="font-montserrat font-bold text-3xl text-primary bg-black p-4  md:pl-6 2xl:pl-10">
                    <h2>
                        Import
                    </h2>
                </div>
                {state &&
                    <div className="row mt-4 p-4 md:p-6 2xl:p-10">

                        <form action="" className={"flex flex-col"}>
                            <h2 className="text-primary text-2xl mb-5">Importeer Gebruikers</h2>
                            <div>
                                <div>
                                    <input  className="mr-2 mb-4" type="checkbox" id={"createPassword"}/>
                                    <label htmlFor="createPassword">Maak automatisch een wachtwoord aan</label>
                                </div>
                                <div>
                                    <input className="mr-2 mb-4" type="checkbox" onChange={(e) => setContainsEmail(e.target.checked)} id={"containsEmail"}/>
                                    <label htmlFor="containsEmail">Gebruik maken van Email</label>
                                </div>
                            </div>

                            <label htmlFor="user" className={"btn w-fit"}>Bestand Uploaden</label>
                            <input type={"file"} id={"user"} onChange={handleFileChange} className="form-control-file hidden" accept=".csv"/>


                        </form>
                        <h2 className="text-primary text-2xl mb-5 mt-10">Importeer bieren</h2>
                        <form className="mt-4">
                            <div className="form-group">
                                <div className="flex flex-col">
                                    <label htmlFor="foto"></label>
                                    <label htmlFor="csv" className="btn w-fit ">Bestand Uploaden</label>
                                    <input type="file"
                                           className="form-control-file hidden" id="csv"
                                           onChange={e => handleFileChosen(e.target.files[0])}
                                           accept=".csv"
                                           disabled="true"
                                    />
                                    {state.image &&
                                        <div className="mt-3">
                                            <img src={state.image} style={{maxWidth: "200px"}}/>
                                        </div>
                                    }
                                    <div className="col"></div>
                                </div>

                                <div className="row mt-4">
                                    <div>{message}</div>
                                </div>

                            </div>

                        </form>
                    </div>
                }
            </div>
        </DefaultLayout>
    )
}

import React, {useState, useEffect} from "react"
import AuthService from "../services/auth.service";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Welcome() {
    const navigate = useNavigate();
    const [state, setState] = useState({email: "", password: ""})

    const { i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
        navigate('/login')
    };

    const [message, setMessage] = useState("")

    function handleSubmit() {
        if (state.email && state.password) {
            AuthService.login(state.email.toLowerCase(), state.password).then(
                () => {
                    navigate("/beers")
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(error.toString())
                    setMessage(resMessage)
                }
            );
        } else {
            setMessage("Leeg e-mailadres of wachtwoord.")
        }
    }

    useEffect(() => {

        if (AuthService.getCurrentUser()) {
            navigate("/beers")
        }
    })

    return (
        <div className="h-screen  bg-gradient-to-br flex from-[#3C3C3C] to-[#18181A]">
            <div className="flex flex-col items-center m-auto  bg-center bg-cover">
                <div className="flex flex-col w-[600px] p-10 py-50 shadow-dark bg-[#181818] text-primary rounded-xl">
                    <div className="font-montserrat font-bold text-3xl text-center">
                        <p className="mb-3 font-extrabold text-5xl">Welcome To</p>
                        <h1> All Belgian Beers</h1>
                    </div>
                    <div className="flex flex-col w-full mt-10">
                        <div className="flex justify-center">
                            <img src="/images/bierboek2.jpg" className="w-32 mr-3" alt="bierboek 2"/>
                            <img src="/images/bierboek.png" className="w-32" alt="bierboek 1"/>
                        </div>

                        <div className="p-10">

                        </div>
                    </div>
                    <div className="flex flex-row justify-around text-2xl">
                        <button className="link-underline" onClick={() => changeLanguage('nl')}>Nederlands</button>
                        <button className="link-underline"  onClick={() => changeLanguage('fr')}>Français</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome
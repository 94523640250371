import React, {useState} from "react"
import AuthService from '../services/auth.service'
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Vergeten = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [showInput, setShowInput] = useState(true)
    const { t } = useTranslation();
    async function handleSubmit() {
        if (email) {
            const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!emailRegexp.test(email)) {
                setMessage("geen geldig e-mailadres!")
            } else {
                let result = await AuthService.sendPasswordResetEmail(email.trim())
                setShowInput(false)
                setMessage(result.data.message)
            }
        } else {
            setMessage("lege e-mail")
        }
    }


    return (
        <div className="h-screen  bg-gradient-to-br flex from-[#3C3C3C] to-[#18181A]">
            <div className="flex flex-col items-center m-auto  bg-center bg-cover">
                <div className="flex flex-col w-1/2 p-10 py-50 shadow-dark bg-[#181818] text-primary rounded-xl">
                    <button type="button" className="afbuild2 text-left -ml-7 -mt-7  p-2 w-fit" onClick={() => navigate("/login")}> {t('Back')}</button>
                    <div className="font-montserrat font-bold text-3xl text-center">
                        <h1>All Belgian Beers</h1>
                    </div>
                    <div className="flex mt-5 flex-col justify-center">
                        <div className="vergeten-title mb-3">{t("Forget_password_intro")}</div>
                        <div className="vergeten-content" /*style={{maxWidth: "231px"}}*/>{t("Forget_password_description")}</div>
                    </div>
                    {showInput &&
                        <>
                            <div className="flex mt-3 flex-col items-center text-xl">
                                <input type="email" value={email} onChange={e => {
                                    setEmail(e.currentTarget.value);
                                    setMessage("")
                                }} className="mt-3 input-field mt-3 input-field w-[350px]" placeholder={t("Email")}/>
                            </div>
                            <div className="mt-3 text-center">
                                <button type="button" className="afbuild2  link-underline p-2" onClick={handleSubmit}>{t("Send")}</button>
                            </div>

                        </>
                    }
                    {
                        message ?
                            <div  style={{marginTop: "10px"}}>
                                <p className="font-extrabold underline">{message}</p>
                            </div>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}
export default Vergeten
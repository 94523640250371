import React, {useEffect, useState} from "react"

import UserService from "../../services/user.service";
import DefaultLayout from "../../layout/defaultLayout";
import {useLocation} from "react-router-dom";

export default function UserBewerkt(props) {

    const {state} = useLocation();
    const [message, setMessage] = useState("");
    const [data, setData] = useState(state);


    useEffect(() => {
        console.log(state)
        setData(state.data)
    }, [state]);


    async function handleSubmit() {
        if (data.password !== "" && data.password !== data.passwordAgain) {
            setMessage("Wachtwoorden komen niet overeen");
            return;
        }
        await UserService.updateUser({...data}).then(
            (resp) => {
                setMessage("bijgewerkt!")
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(error.toString())
                setMessage(error.toString())
            }
        );
    }


    return (
        <DefaultLayout index="1">
            <div className="pagina-gebruikers d-flex flex-column mb-4">
                <div className="font-montserrat font-bold text-3xl text-primary bg-black p-4  md:pl-6 2xl:pl-10">
                    <h2>
                        Bewerk gebruiker {data?.name}
                    </h2>
                </div>
                {data &&
                    <form className="mt-4 p-4 md:p-6 2xl:p-10">
                        <div className="form-group flex-col flex ml-auto mr-auto ">
                            <div className="flex flex-row justify-around ">
                                <div className="flex flex-col w-96">
                                    <label htmlFor="naambrouwer">Naam brouwer:</label>
                                    <input type="text" className="form-control input-field-light" id="naambrouwer" value={data.nameBrouwer} placeholder="brouwer"
                                           onChange={e => setData({...data, nameBrouwer: e.target.value})}
                                    />
                                </div>
                                <div className=" flex flex-col w-96">
                                    <label htmlFor="voornaam">Website brouwerij:</label>
                                    <input type="text" className="form-control input-field-light" id="voornaam" value={data.website} placeholder="Peter"
                                           onChange={e => setData({...data, website: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row mt-4 justify-around">
                                <div className="flex flex-col w-96">
                                    <label htmlFor="woonplaats">Gemeente/stad brouwerij:</label>
                                    <input type="woonplaats" className="form-control input-field-light" id="woonplaats" value={data.gemeente} placeholder="Brugge"
                                           onChange={e => setData({...data, gemeente: e.target.value})}/>
                                </div>
                                <div className="flex flex-col w-96">
                                </div>
                            </div>


                            <div className="flex flex-row mt-4 justify-around">
                                <div className=" flex flex-col w-96">
                                    <label htmlFor="voornaam">Naam Contactpersoon:</label>
                                    <input type="text" className="form-control input-field-light" id="voornaam" value={data.name} placeholder="Peter"
                                           onChange={e => setData({...data, name: e.target.value})}
                                    />
                                </div>
                                <div className="flex flex-col w-96">
                                    <label htmlFor="email">E-mail:</label>
                                    <input type="email" className="form-control input-field-light" id="email" value={data.email} placeholder="peterdebakker@gmail.com"
                                           onChange={e => setData({...data, email: e.target.value})}/>
                                </div>
                            </div>

                            <div className="flex flex-row mt-4 justify-around">
                                <div className="flex flex-col w-96">
                                    <label htmlFor="wachtwoord">Wachtwoord</label>
                                    <input type="text" className="form-control input-field-light" id="wachtwoord" value={data.password} placeholder="Wachtwoord"
                                           onChange={e => setData({...data, password: e.target.value})}
                                    />
                                </div>
                                <div className="flex flex-col w-96">
                                    <label htmlFor="herhaal">Herhaal wachtwoord</label>
                                    <input type="text" className="form-control input-field-light" id="herhaal" value={data.passwordAgain} placeholder="Herhaal wachtwoord"
                                           onChange={e => setData({...data, passwordAgain: e.target.value})}
                                    />
                                </div>
                            </div>

                            {
                                message &&
                                <div className="row mt-4">
                                    <div>{message}</div>
                                </div>
                            }

                            <div className="row mt-4">
                                <div className="col">
                                    <div className="flex justify-end">
                                        <button type="button" className="btn"
                                                onClick={() => handleSubmit()}
                                        >+ Update
                                        </button>
                                    </div>
                                </div>
                                <div className="col">

                                </div>
                            </div>
                        </div>

                    </form>
                }
            </div>
        </DefaultLayout>
    )
}

import React, {useState, useEffect} from "react"

import UserService from '../../services/user.service'
import AuthService from '../../services/auth.service'
//import { FaSearch } from 'react-icons/fa';
import Sidebar from "../../components/Sidebar";
import {useNavigate} from "react-router-dom";
import DefaultLayout from "../../layout/defaultLayout";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

export default function Beers() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [currentPage, setCurrentPage] = useState(0);
    const initialState = {
        name: '',
        nameBrouwer: '',
        gisting: '',
        stijl: '',
        samenstelling: '',
        alcoholpercentage: '',
        kleur: '',
        smaak: '',
    }

    const dbStructure = {
        name: '',
        nameBrouwer: '',
        gisting_nl: '',
        gisting_fr: '',
        gisting_en: '',
        stijl_nl: '',
        stijl_fr: '',
        stijl_en: '',
        samenstelling_nl: '',
        samenstelling_fr: '',
        samenstelling_en: '',
        alcoholpercentage: '',
        kleur_nl: '',
        kleur_fr: '',
        kleur_en: '',
        smaak_nl: '',
        smaak_fr: '',
        smaak_en: '',
    }

    const [state, setState] = useState(
        initialState
    )

    const [bills, setBills] = useState(null)
    const [someBills, setSomeBills] = useState(null)
    const [search, setSearch] = useState("")
    const [fileError, setFileError] = useState(false);
    const [addbill, setAddbill] = useState(false)
    const [message, setMessage] = useState("")
    const [change, setChange] = useState("")
    const [curID, setCurID] = useState("")
    const [isUploading , setIsUploading] = useState(false)
    const resultsPerPage = 10

    async function handleSubmit() {
        if (state.name) {
            let currentLanguage = i18n.language;
            for (let key in state) {
                if (state[key] && key !== "name" && key !== "nameBrouwer" && key !== "alcoholpercentage" && key !== "label") {
                    dbStructure[key + "_" + currentLanguage] = state[key]
                } else {
                    dbStructure[key] = state[key]
                }
            }
            setIsUploading(true);
            const res = await UserService.createBeer(dbStructure)
            // setState(initialState)
            console.log("res",res)
            setIsUploading(false);
             if (res.status === 200 )   {
                 setMessage("nieuw bier is aangemaakt")
                 setTimeout(() => {
                     navigate(0)
                 }, 1000)
             } else {
                 setMessage("Er is iets misgegaan, probeer het later opnieuw")
             }
        } else {
            setMessage("Beer name missing")
        }
    }

    useEffect(() => {

        if (!AuthService.getCurrentUser()) {
            navigate("/login")
        }

        async function fetchData() {
            const content = await UserService.getBeers()

            if (content) {
                //console.log(content)
                setBills(content.data)

                if (content.data && content.data.length > resultsPerPage) {
                    let cont = [...content.data]
                    setSomeBills(cont.splice(0, resultsPerPage))
                } else
                    setSomeBills(content.data)
            }
        }

        fetchData();
    }, [])

    function handleDelete(id) {
        setCurID(id)
    }

    function confirmDelete() {
        async function deleteA() {
            const result = await UserService.deleteBeer(curID)
        }

        deleteA()
        let ss = someBills.filter(x => x._id !== curID)
        setSomeBills(ss)
        setCurID("")
        setChange(ss)
    }

    async function handleSearch() {
        const content = await UserService.findBill(search.trim())
        setBills(content.data)
    }

    function handleEdit(data) {
        navigate("/beer-bewerkt", {
            state: {data},
        })
    }

    function handleResults(e, bill) {
        e.preventDefault()
        navigate("/results", {
            state: {bill},
        })
    }

    /*const Pages = () => {
        let links = []
        if (bills && bills.length) {
            for (let i = 0; i < Math.ceil(bills.length / resultsPerPage); i++) {
                links.push(<div key={i} className="mr-1"><a href="" className="mr-3" onClick={(e) => handlePage(e, i)}>{i + 1}</a>,</div>)
            }
        }
        return links
    }*/

    const MAX_VISIBLE_PAGES = 5;
    const Pages = () => {
        const pageLinks = [];

        if (bills && bills.length) {
            const totalPages = Math.ceil(bills.length / resultsPerPage);

            let startPage = Math.max(currentPage - Math.floor(MAX_VISIBLE_PAGES / 2), 0);
            let endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

            if (endPage - startPage + 1 < MAX_VISIBLE_PAGES) {
                // Adjust startPage when near the end to maintain the number of visible pages
                startPage = Math.max(endPage - MAX_VISIBLE_PAGES + 1, 0);
            }

            for (let i = startPage; i <= endPage; i++) {
                pageLinks.push(
                    <span key={i}>
          <a href="#" onClick={(e) => handlePage(e, i )}>
            {i + 1}
          </a>
                        {i < endPage && <span className="mr-1">,</span>}
        </span>
                );
            }

            if (startPage > 0) {
                pageLinks.unshift(<span key="ellipsis-start">...</span>);
            }

            if (endPage < totalPages - 1) {
                pageLinks.push(<span key="ellipsis-end">...</span>);
            }
        }

        return <div className="pagination">{pageLinks}</div>;
    };

    function handlePage(e, index) {
        e.preventDefault()
        if (bills) {
            let newBills = [...bills]
            newBills = newBills.splice(index * resultsPerPage, resultsPerPage)
            setSomeBills(newBills)
            setCurrentPage(index)
        }
    }

    function handleBack() {
        setState(initialState)
        setAddbill(false)
    }

    function handleTerug() {
        setAddbill(false)
    }

    function scrollUp() {
        if (window)
            window.scroll({top: 0, left: 0, behavior: 'smooth'})
    }

    function checkFileSize(e) {
        setFileError(false);
        let maxSize = 5000000;
        let input = e.target;
        console.log(input);
        if (input.files.length > 0) {
            let fileSize = input.files[0].size;
            if (fileSize > maxSize) {
                e.target.file = null;
                input.value = '';
                setFileError(true);
                return;
            }
        }
        console.log(input.files[0])
        setState({...state, label: input.files[0]})
    }

    return (
        <DefaultLayout>
            {curID &&
                <div id="myNav" className="absolute top-1/4 w-full ">
                    <div className=" bg-gradient-to-br text-center from-[#3C3C3C] to-[#18181A] w-fit ml-auto mr-auto flex align-items-start justify-content-center row">
                        <div className="flex-column p-5" style={{minWidth: "200px"}}>
                            <div className="flex justify-center font-extrabold text-3xl tex text-primary">Wissen?</div>
                            <div className="flex-row flex align-items-center text-white justify-around mt-3">
                                <button type="button" className="link-underline" onClick={() => confirmDelete()}>Ja</button>
                                <button type="button" className="link-underline" onClick={() => setCurID("")}>Nee</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="pagina-gebruikers d-flex flex-column mb-4">
                {
                    addbill ?
                        <>


                            <div className="font-montserrat font-bold text-3xl text-primary bg-black p-4  md:pl-6 2xl:pl-10">
                                <h2>
                                    {t("Add_beer")}
                                </h2>
                            </div>
                            <div className="row mt-4 p-4 md:p-6 2xl:p-10">
                                <div className="col">

                                    <div className="flex flex-col w-96 mt-3">
                                        <label htmlFor="naambier">{t("Name_beer")}:</label>
                                        <input type="text" className="input-field-light" id="naambier" placeholder=""
                                               value={state.name}
                                               onChange={e => setState({...state, name: e.currentTarget.value})}
                                        />
                                    </div>

                                    <div className="flex flex-col w-96 mt-3">
                                        <label htmlFor="naambrouwer">{t("Name_brewer")}:</label>
                                        <input type="text" className="input-field-light" id="naambrouwer" placeholder=""
                                               value={state.nameBrouwer}
                                               onChange={e => setState({...state, nameBrouwer: e.currentTarget.value})}
                                        />
                                    </div>

                                    <div className="flex flex-col w-96 mt-3">
                                        <label htmlFor="gistingtekstNL">{t("Fermentation_process")}:</label>
                                        <input maxLength={60} type="text" className="input-field-light" id="gistingtekstNL" placeholder={t("Fermentation_process_example")}
                                               value={state.gisting}
                                               onChange={e => setState({...state, gisting: e.currentTarget.value})}
                                        />
                                    </div>


                                    <div className="flex flex-col w-96 mt-3">
                                        <label htmlFor="stijltekstNL">{t("Style")}:</label>
                                        <input maxLength={22} type="text" className="input-field-light" id="stijltekstNL" placeholder={t("Style_example")}
                                               value={state.stijl}
                                               onChange={e => setState({...state, stijl: e.currentTarget.value})}
                                        />
                                    </div>

                                    <div className="flex flex-col w-96 mt-3">
                                        <label htmlFor="samenstellingtekstNL">{t("Ingredients")}:</label>
                                        <input maxLength={60} type="text" className="input-field-light" id="samenstellingtekstNL" placeholder=""
                                               value={state.samenstelling}
                                               onChange={e => setState({...state, samenstelling: e.currentTarget.value})}
                                        />
                                    </div>


                                    <div className="flex flex-col w-96 mt-3">
                                        <label htmlFor="alcoholpercentage">{t("Alcoholpercentage")}:</label>
                                        <input maxLength={10} type="text" className="input-field-light" id="alcoholpercentage" placeholder=""
                                               value={state.alcoholpercentage}
                                               onChange={e => setState({...state, alcoholpercentage: e.currentTarget.value})}
                                        />
                                    </div>

                                    <div className="flex flex-col w-96 mt-3">
                                        <label htmlFor="kleurFR">{t("Color_clarity")}:</label>
                                        <input maxLength={22} type="text" className="input-field-light" id="kleurNL" placeholder=""
                                               value={state.kleur}
                                               onChange={e => setState({...state, kleur: e.currentTarget.value})}
                                        />
                                    </div>


                                    <div className="flex flex-col w-96 mt-3">
                                        <label htmlFor="smaaktekstNL">{t("Character_taste_aroma")}:</label>
                                        <input maxLength={180} type="text" className="input-field-light" id="smaaktekstNL" placeholder=""
                                               value={state.smaak}
                                               onChange={e => setState({...state, smaak: e.currentTarget.value})}
                                        />
                                    </div>
                                    <div className="flex flex-col w-96 mt-3">
                                        <label htmlFor="label">{t("Label")} (max 5Mb):</label>
                                        <input type="file" name="label" className="mt-3" id="label" accept="image/*" size="5000000"
                                               onChange={e => checkFileSize(e)}
                                        />
                                        {fileError && <p className="text-red-500">{t("Max_file_size")}</p>}
                                    </div>

                                    {message &&
                                        <div className="mt-4 text-red-500">
                                            {message}
                                        </div>
                                    }

                                    <div className="mt-4 flex flex-row w-50 justify-content-between">
                                        <div>
                                            <button onClick={() => handleTerug()} type="button" className="btn ml-3">{t("Back")}</button>
                                        </div>
                                        <div className="ml-3">
                                            <button type="button" className="btn" onClick={() => handleSubmit()}>
                                                {t("Add")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="font-montserrat font-bold flex justify-between text-3xl text-primary bg-black p-4  md:pl-6 2xl:pl-10 md:pr-6 2xl:pr-10">
                                <h2 className="mt-auto mb-auto">
                                    {t("Beers")}
                                </h2>
                                <button type="button" className="text-xl " onClick={() => setAddbill(true)}>+ {t("Add_beer")}</button>
                            </div>
                            <div className="mt-4 p-4 md:p-6 2xl:p-10">


                                <div className="pagina-window d-flex flex-column justify-content-center mt-4" style={{border: "none"}}>
                                    <table className="w-full mt-10">
                                        <thead className="text-xs text-gray-700 text-left  bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="pl-14 ">
                                                {t("Name_beer")}
                                            </th>
                                            <th scope="col" className=" py-3">
                                                {t("Name_brewer")}
                                            </th>
                                            <th scope="col" className=" py-3">
                                            </th>

                                        </tr>
                                        </thead>
                                        <tbody className={""}>

                                        {
                                            someBills && someBills.map((x, i) =>
                                                <tr key={i} className="odd:bg-white odd:dark:bg-gray-200 even:bg-gray-50 even:dark:bg-gray-50 border-b text-left ">
                                                    <th scope="row" className="  font-medium text-gray-900 whitespace-nowrap pl-14">
                                                        {` `} {x.name} {` `}
                                                    </th>

                                                    <td className="col-md-2">
                                                        {` `} {x.nameBrouwer} {` `}
                                                    </td>

                                                    <td className="flex items-center justify-around py-3">
                                                        <button type="button" className="btn" onClick={() => handleEdit(x)}>{t("Edit")}</button>
                                                        <button type="button" className="btn" onClick={() => handleDelete(x._id)}>{t("Delete")}</button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>

                                </div>
                                <div className="mt-3 flex flex-row  justify-end">
                                    <Pages/>
                                </div>
                                <div className="mt-3 flex flex-row  justify-end">
                                 <button type="button" className="text-xl bg-primary p-3 rounded font-medium" onClick={() => setAddbill(true)}>+ {t("Add_beer")}</button>
                                </div>
                            </div>
                        </>
                }
            </div>
        </DefaultLayout>
    )
}

import axios from 'axios';
import authHeader from './auth-header';

const config = require("../config/api");
const API_URL = `${config.API_URL}/`;

class UserService {

    getUserBoard() {
        let res
        try {
            res = axios.get(API_URL + 'user', {headers: authHeader()})
        } catch (err) {
        }
        return res
    }

    getModeratorBoard() {
        let res
        try {
            res = axios.get(API_URL + 'mod', {headers: authHeader()})
        } catch (err) {
        }
        return res
    }

    getAdminBoard() {
        let res
        try {
            res = axios.get(API_URL + 'admin', {headers: authHeader()})
        } catch (err) {
        }
        return res
    }

    async getUserData(email) {
        let res
        const headers = await authHeader()
        try {
            res = axios.post(API_URL + "userdata", {email: email}, {headers: headers})
        } catch (err) {
        }
        return res
    }

    async updateUser(data) {
        let res
        const headers = await authHeader()
        try {
            res = axios.post(API_URL + "update-user", {...data}, {headers: headers})
        } catch (err) {
        }
        return res
    }

    getUsers() {
        let res
        const headers = authHeader()
        try {
            res = axios.get(API_URL + "all-users", {headers: headers})
        } catch (err) {
        }
        return res
    }

    findUsers(search) {
        let res
        const headers = authHeader()
        try {
            res = axios.post(API_URL + "find-users", {search: search}, {headers: headers})
        } catch (err) {
        }

        return res
    }

    deleteUser(id) {
        let res
        const headers = authHeader()
        try {
            res = axios.post(API_URL + "delete-user", {id: id}, {headers: headers})
        } catch (err) {
        }

        return res
    }

    async createBeer(data) {
        let res;
        const headers = authHeader();
        const formData = new FormData();

        // Append all key-value pairs from the data object to the FormData
        Object.entries(data).forEach(([key, value]) => {
            if (value !== null && value !== undefined) {
                formData.append(key, value);
            }
        });

        try {
            if (data.label) {
                formData.append('label', data.label);
            }

            res = await axios.post(API_URL + "create-beer", formData, { headers: { ...headers, 'Content-Type': 'multipart/form-data' } });
        } catch (err) {
            console.error(err);
            return err
            // Handle the error if needed
        }
        return res;
    }



    async updateBeer(data) {
        let res;
        const headers = authHeader();
        const formData = new FormData();
        // Append all key-value pairs from the data object to the FormData
        Object.entries(data).forEach(([key, value]) => {
            if (value !== null && value !== undefined && value !== "") {
                formData.append(key, value);
            }
        });

        try {
            if (data.label) {
                console.log(data.label);
                formData.append('label', data.label);
            }

            // Use await with axios.post
            res = await axios.post(API_URL + "update-beer", formData, { headers: { ...headers, 'Content-Type': 'multipart/form-data' } });
        } catch (err) {
            console.error(err);
            return err;
        }
        return res;
    }


    deleteBeer(id) {
        let res
        const headers = authHeader()
        try {
            res = axios.post(API_URL + "delete-beer", {id: id}, {headers: headers})
        } catch (err) {
        }

        return res
    }

    findBeer(search) {
        let res
        const headers = authHeader()
        try {
            res = axios.post(API_URL + "find-beer", {search: search}, {headers: headers})
        } catch (err) {
        }

        return res
    }

    getBeers() {
        let res
        const headers = authHeader()
        try {
            res = axios.get(API_URL + 'get-beers', {headers: headers})
        } catch (err) {
        }
        return res
    }

    getHistory() {
        let res
        const headers = authHeader()
        try {
            res = axios.get(API_URL + "history", {headers: headers})
        } catch (err) {
        }
        return res
    }

    import(data) {
        let res
        const headers = authHeader()
        try {
            res = axios.post(API_URL + "import", {data: data}, {headers: headers})
        } catch (err) {
        }

        return res
    }

    export() {
        let res
        const headers = authHeader()
        try {
            res = axios.get(API_URL + "export", {headers: headers})
        } catch (err) {
        }

        return res
    }
    userExport(active, inactive) {
        let res
        const headers = authHeader()
        try {
            res = axios.get(API_URL + "user-export?active="+active + "&inactive="+inactive  , {headers: headers})
        } catch (err) {
        }

        return res
    }
}

export default new UserService();
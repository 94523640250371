import React, {useState, useEffect} from "react"

import UserService from '../../services/user.service'
import AuthService from "../../services/auth.service";

import DefaultLayout from "../../layout/defaultLayout";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Profile(props) {
    const navigate = useNavigate();
    const [state, setState] = useState({})
    const [message, setMessage] = useState("")
    const { t } = useTranslation();

    function validateEmail(email) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return (true)
        }
        return (false)
    }

    async function handleSubmit() {
        setMessage("")
        if (validateEmail(state.email)) {
            //if(state.password && state.passwordAgain)
            //{
            if (state.password === state.passwordAgain) {
                await UserService.updateUser({...state}).then(
                    (resp) => {
                        setMessage("bijgewerkt!")
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(error.toString())
                        setMessage(error.toString())
                    }
                );
            } else {
                setMessage("Wachtwoorden moeten hetzelfde zijn!")
            }
            //}
        } else {
            setMessage("e-mailadres niet geldig")
        }
    }

    useEffect(() => {

        let user = AuthService.getCurrentUser()

        if (!user) {
            navigate("/login")
        }

        async function fetchData() {
            try {
                const content = await UserService.getUserData(user.email)

                if (content && content.data && content.data.user) {
                    //console.log(content)
                    const {nameBrouwer, name, website, email, gemeente} = content.data.user
                    setState({nameBrouwer: nameBrouwer,  name: name, website: website, email: email, gemeente: gemeente})
                }
            } catch (err) {
                console.log(err)
            }
        }

        fetchData();
    }, [])

    return (
        <DefaultLayout>
            <div className=" flex flex-col mb-4 w-full">
                <div className="font-montserrat font-bold text-3xl text-primary bg-black p-4  md:pl-6 2xl:pl-10">
                    <h2>
                        {t('Profile')}
                    </h2>
                </div>
                {state &&
                    <form className=" row mt-4 p-4 md:p-6 2xl:p-10">
                        <div className="form-group flex-col ">
                            <div className="flex flex-row justify-around">
                                <div className="flex flex-col w-96">
                                    <label htmlFor="naambrouwer">{t("Name_brewer")}:</label>
                                    <input type="text" className="form-control input-field-light" id="naambrouwer" value={state.nameBrouwer} placeholder="brouwer"
                                           onChange={e => setState({...state, nameBrouwer: e.target.value})}
                                    />
                                </div>
                                <div className="flex flex-col w-96">
                                </div>
                            </div>
                            <div className="flex flex-row mt-4 justify-around">
                                <div className="flex flex-col w-96">
                                    <label htmlFor="voornaam">{t("Name_contact")}:</label>
                                    <input type="text" className="form-control input-field-light" id="voornaam" value={state.name} placeholder={t("Name_contact")}
                                           onChange={e => setState({...state, name: e.target.value})}
                                    />
                                </div>
                                <div className="flex flex-col w-96 ">
                                    <label htmlFor="familienaam">{t("Website")}:</label>
                                    <input type="text" className="form-control input-field-light" id="familienaam" value={state.website} placeholder={t("Website")}
                                           onChange={e => setState({...state, website: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row mt-4 justify-around">
                                <div className="flex flex-col w-96 ">
                                    <label htmlFor="email">{t("Email_contact")}:</label>
                                    <input type="email" className="form-control input-field-light" id="email" value={state.email} placeholder={t("Email")}
                                           onChange={e => setState({...state, email: e.target.value})}
                                    />
                                </div>
                                <div className="col d-flex align-items-end w-96">
                                </div>
                            </div>

                            <div className="flex flex-row mt-4 justify-around">
                                <div className="flex flex-col w-96">
                                    <label htmlFor="woonplaats">{t("Address")}:</label>
                                    <input type="woonplaats" className="form-control input-field-light" id="woonplaats" value={state.gemeente} placeholder="Brugge"
                                           onChange={e => setState({...state, gemeente: e.target.value})}
                                    />
                                </div>
                                <div className="flex flex-col w-96">
                                </div>
                            </div>

                            <div className="flex flex-row mt-4 justify-around">
                                <div className="flex flex-col w-96">
                                    <label htmlFor="wachtwoord">{t("Password")}:</label>
                                    <input type="text" className="form-control input-field-light" id="wachtwoord" value={state.password || ""} placeholder={t("Password")}
                                           onChange={e => setState({...state, password: e.currentTarget.value})}
                                    />
                                </div>
                                <div className="flex flex-col w-96">
                                    <label htmlFor="herhaal">{t("Repeat_password")}:</label>
                                    <input type="text" className="form-control input-field-light" id="herhaal" value={state.passwordAgain || ""} placeholder={t("Repeat_password")}
                                           onChange={e => setState({...state, passwordAgain: e.currentTarget.value})}
                                    />
                                </div>
                            </div>


                            <div className="flex flex-row mt-4">
                                <div>{message}</div>
                            </div>

                            <div className="flex justify-around">
                                <div className="flex flex-col w-96">
                                </div>
                                <div className="flex flex-col w-96">
                                    <button type="button" className="ml-auto btn"
                                            onClick={() => handleSubmit()}
                                    >{t("Update")}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                }
            </div>
        </DefaultLayout>
    )
}

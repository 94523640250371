import React, {useState, useEffect} from "react"
import AuthService from "../services/auth.service";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Login() {
    const navigate = useNavigate();
    const [state, setState] = useState({email: "", password: ""})
    const { t } = useTranslation();
    const [message, setMessage] = useState("")

    function handleSubmit() {
        if (state.email && state.password) {
            AuthService.login(state.email.toLowerCase(), state.password).then(
                () => {
                    navigate("/beers")
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(error.toString())
                    setMessage(resMessage)
                }
            );
        } else {
            setMessage("Leeg e-mailadres of wachtwoord.")
        }
    }

    function handleKeydown(event) {
        if (event.key === "Enter") {
            handleSubmit()
        }

    }

    useEffect(() => {

        if (AuthService.getCurrentUser()) {
            navigate("/beers")
        }
    })

    return (
        <div className="h-screen  bg-gradient-to-br flex from-[#3C3C3C] to-[#18181A] ">
            <div className="flex flex-col items-center m-auto   bg-center bg-cover">
                <div className="flex flex-col p-10 py-50 w-[600px] shadow-dark bg-[#181818] text-primary rounded-xl">
                    <div className="font-montserrat font-bold text-3xl text-center">
                          <h1 >All Belgian Beers</h1>
                    </div>
                    <div className="flex flex-col w-full mt-10">
                        <div className="flex justify-center">
                            <img src="/images/bierboek2.jpg" className="w-32 mr-3 h-full" alt="bierboek 2"/>
                            <img src="/images/bierboek.png" className="w-32 h-full" alt="bierboek 1"/>
                        </div>

                        <div className="p-10">
                            <p>{t("Login_text")}</p>
                        </div>
                    </div>
                    <div >

                        <form  className="flex flex-col items-center mt-3 text-xl" method="post" onSubmit={handleSubmit} onKeyDown={handleKeydown}>

                        <input type="email" name="email" className="form-input mt-3 input-field w-[350px]" value={state.email} placeholder={t("EmailOrUsername")}
                               onChange={e => {
                                   setState({...state, email: e.target.value});
                                   setMessage("");
                               }}/>
                        <input type="password" name="password" className="form-input mt-2 input-field w-[350px]" value={state.password} placeholder={t("Password")}
                               onChange={e => {
                                   setState({...state, password: e.target.value});
                                   setMessage("");
                               }}/>
                        {message &&
                            <div className="mt-1 mb-1">
                                {message}
                            </div>
                        }
                        </form>
                    </div>
                    <div className="mt-3 text-center">
                        <button type="button" className="afbuild2 link-underline p-2" onClick={handleSubmit}>{t("Login")}</button>
                    </div>
                    <div className="mt-3 flex justify-center">
                        <Link to="/vergeten" className="text-393939 link-underline p-2">{t("Forget_password")}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
import React, {useState, useEffect} from "react"


import UserService from '../../services/user.service'
import AuthService from '../../services/auth.service'
//import { FaSearch } from 'react-icons/fa';
import DefaultLayout from "../../layout/defaultLayout";
import {useNavigate} from "react-router-dom";

export default function History() {
    const navigate = useNavigate();
    const [state, setState] = useState({})

    const [bills, setBills] = useState(null)
    const [someBills, setSomeBills] = useState(null)
    const resultsPerPage = 10


    useEffect(() => {

        if (!AuthService.getCurrentUser()) {
            navigate("/login")
        }

        async function fetchData() {
            const content = await UserService.getHistory()

            if (content) {
                //console.log(content)
                setBills(content.data)

                if (content.data && content.data.length > resultsPerPage) {
                    let cont = [...content.data]
                    setSomeBills(cont.splice(0, resultsPerPage))
                } else
                    setSomeBills(content.data)
            }
        }

        fetchData();
    }, [])

    const Pages = () => {
        let links = []
        if (bills && bills.length) {
            for (let i = 0; i < Math.ceil(bills.length / resultsPerPage); i++) {
                links.push(<div key={i} className="mr-2"><a href="" onClick={(e) => handlePage(e, i)}>{i + 1}</a>{`, `}</div>)
            }
        }
        return links
    }

    function handlePage(e, index) {
        e.preventDefault()
        if (bills) {
            let newBills = [...bills]
            newBills = newBills.splice(index * resultsPerPage, resultsPerPage)
            setSomeBills(newBills)
        }
    }

    return (
        <DefaultLayout>
            <div className="pagina-gebruikers d-flex flex-column mb-4">
                {someBills &&
                    <>
                        <div className="font-montserrat font-bold text-3xl text-primary bg-black p-4  md:pl-6 2xl:pl-10">
                            <h2>
                                Geschiedenis
                            </h2>
                        </div>
                        <div className="p-4 md:p-6 2xl:p-10">



                        <table className="w-full mt-10">
                            <thead className="text-xs text-gray-700 text-left  bg-gray-50 ">
                            <tr >
                                <th scope="col" className="pl-14 ">
                                    Bier naam
                                </th>
                                <th scope="col" className=" py-3">
                                    Naam Brouwer
                                </th>
                                <th scope="col" className=" py-3">
                                    Updated at
                                </th>

                            </tr>
                            </thead>
                            <tbody className={""}>
                            {

                                someBills && someBills.map((x, i) =>
                                    <tr className="odd:bg-white odd:dark:bg-gray-200 even:bg-gray-50 even:dark:bg-gray-50 border-b text-left">
                                        <th scope="row" className=" py-4 font-medium text-gray-900 whitespace-nowrap pl-14">
                                            {x.name}
                                        </th>
                                        <td className="py-4">
                                            {x.nameBrouwer}
                                        </td>
                                        <td className=" py-4">
                                            {x.updatedAt}
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>


                        <div className="mt-3 flex flex-row flex-wrap justify-end">
                            <Pages/>
                        </div>
                        </div>
                    </>
                }
            </div>
        </DefaultLayout>
    )
}

import React, {useEffect, useState} from 'react';
//import Header from '../components/Header/index';
import Sidebar from '../components/Sidebar/index';

const DefaultLayout = ({children}) => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [loadBeer, setLoadBeer] = useState(false);
    const [loadStamp, setLoadStamp] = useState(false);
    const [loadFade, setLoadFade] = useState(false);
    const [hide, setHide] = useState(false);
    setTimeout(() => {


    }, 1000)
    useEffect(() => {
        setTimeout(() => {
            setLoadBeer(true);
            setTimeout(() => {
                setLoadStamp(true);
                setTimeout(() => {
                    setLoadFade(true);
                    setTimeout(() => {
                        setHide(true);
                    }, 2000);
                }, 2000)
            }, 2000)
        })
    })

    return (
        <div className="dark:bg-boxdark-2 dark:text-bodydark font-montserrat">
            {/* <!-- ===== Page Wrapper Start ===== --> */}
            <div className="flex h-screen overflow-hidden">
                {/* <!-- ===== Sidebar Start ===== --> */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
                {/* <!-- ===== Sidebar End ===== --> */}
                {/* <!-- ===== Content Area Start ===== --> */}
                <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
                    {/* <!-- ===== Header Start ===== --> */}

                    {/* <!-- ===== Header End ===== --> */}

                    {/* <!-- ===== Main Content Start ===== --> */}
                    <main>
                        <div className="mx-auto max-w-screen-2xl ">
                            {children}
                        </div>
                    </main>
                    {/* <!-- ===== Main Content End ===== --> */}
                </div>
                <footer className={`fixed bottom-0  right-0 ${loadBeer? "beer":"translate-y-[200px]" } ${hide?"hidden":""} ${loadFade?"fadeOut":""}`}>
                    <div>
                        <div><img src="/images/shutterstock_708845434.png" className="w-screen h-[20vh] object-cover object-top" alt=""/></div>
                        <div><img className={`absolute bottom-0 w-60 opacity-0 ${loadStamp?"stamp":""}`} src="/images/Site+2500.png"/></div>
                    </div>
                </footer>
                {/* <!-- ===== Content Area End ===== --> */}
            </div>
            {/* <!-- ===== Page Wrapper End ===== --> */}
        </div>
    );
};

export default DefaultLayout;

export default function authHeader() {
	const data = localStorage.getItem('user')
 	const user = JSON.parse(data);

  if (user && user.accessToken) {
    // return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
      console.log(user.accessToken)
    return { 'x-access-token': user.accessToken };

  } else {
    return {};
  }
}

import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import Login from "./pages/login";
import Vergeten from "./pages/vergeten";
import Beers from "./pages/dashboard/beers";
import Users from "./pages/dashboard/users";
import Profile from "./pages/dashboard/profile";
import Export from "./pages/dashboard/export";
import Import from "./pages/dashboard/import";
import History from "./pages/dashboard/history";
import BeerBewerkt from "./pages/dashboard/beer-bewerkt";
import Welcome from "./pages/welcome";
import {I18nextProvider} from "react-i18next";
import i18n from "./config/i18n";
import UserBewerkt from "./pages/dashboard/user-bewerkt";


function App() {
    return (
        <>

            <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                <Welcome/>
                            </>
                        }
                    />

                    <Route
                        path="/login"
                        element={
                            <>
                                <Login/>
                            </>
                        }
                    />
                    <Route
                        path="/vergeten"
                        element={
                            <>
                                <Vergeten/>
                            </>
                        }
                    />
                    <Route
                        path="/beers"
                        element={
                            <>
                                <Beers/>
                            </>
                        }
                    />
                    <Route
                        path="/users"
                        element={
                            <>
                                <Users/>
                            </>
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <>
                                <Profile/>
                            </>
                        }
                    />
                    <Route
                        path="/export"
                        element={
                            <>
                                <Export/>
                            </>
                        }
                    />
                    <Route
                        path="/import"
                        element={
                            <>
                                <Import/>
                            </>
                        }
                    />
                    <Route
                        path="/history"
                        element={
                            <>
                                <History/>
                            </>
                        }
                    />
                    <Route
                        path="/beer-bewerkt"
                        element={
                            <>
                                <BeerBewerkt/>
                            </>
                        }
                    />

                    <Route
                        path="/user-bewerkt"
                        element={
                            <>
                                <UserBewerkt/>
                            </>
                        }
                    />
                </Routes>
            </BrowserRouter>
            </I18nextProvider>
        </>
    );
}

export default App;

import React, {useState, useEffect} from "react"

import UserService from '../../services/user.service'
import AuthService from "../../services/auth.service";

import {CSVLink, CSVDownload} from "react-csv";
import DefaultLayout from "../../layout/defaultLayout";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCsv, faImages} from "@fortawesome/free-solid-svg-icons";


export default function Export(props) {
    const navigate = useNavigate();
    const [message, setMessage] = useState("")
    const [content, setContent] = useState(null)
    const [activeUsers, setActiveUsers] = useState(true)
    const [inactiveUsers, setInactiveUsers] = useState(true)

    useEffect(() => {

        let user = AuthService.getCurrentUser()

        if (!user) {
            navigate("/login")
        }

    }, [])

    async function handleExport() {
        const content = await UserService.export()
        setContent(content.data)
    }

    async function handleUserExport() {
        const content = await UserService.userExport(activeUsers, inactiveUsers)
        setContent(content.data)
    }

    return (
        <DefaultLayout>
            <div className="pagina-gebruikers d-flex flex-column mb-4">
                <div className="font-montserrat font-bold text-3xl text-primary bg-black p-4  md:pl-6 2xl:pl-10">
                    <h2>
                        Export
                    </h2>
                </div>
                <div className="row mt-4 p-4 md:p-6 2xl:p-10">
                    <h2 className="text-primary text-2xl mb-5 mt-10">Exporteer gebruikers</h2>

                    <div>
                        <input checked={activeUsers} onChange={()=>{ setActiveUsers(!activeUsers)}} className="mr-2 mb-4" type="checkbox" id={"activeUser"}/>
                        <label htmlFor="activeUser">Active gebruikers</label>
                    </div>
                    <div>
                        <input checked={inactiveUsers} onChange={()=>{ setInactiveUsers(!inactiveUsers)}} className="mr-2 mb-4" type="checkbox" id={"inactiveUser"}/>
                        <label htmlFor="inactiveUser">Inactive gebruikers</label>
                    </div>
                    <div className="row mt-4">
                        <div className="col">
                            <button className="btn" onClick={() => handleUserExport()}>Export aanmaken</button>
                        </div>
                        <div className="col"></div>
                    </div>

                    <div className="row mt-4">
                        <div>{message}</div>
                    </div>
                    {
                        content &&
                        <div className="flex ">
                            <CSVLink className="btn !bg-gradient-to-br mr-3  from-[#3C3C3C] to-[#18181A] !text-white !p-3" data={content}>Download <FontAwesomeIcon icon={faFileCsv}/></CSVLink>
                        </div>
                    }


                    <h2 className="text-primary text-2xl mb-5 mt-10">Exporteer bieren</h2>
                    <div className="row mt-4">
                        <div className="col">
                            <button className="btn" onClick={() => handleExport()}>Export aanmaken</button>
                        </div>
                        <div className="col"></div>
                    </div>

                    <div className="row mt-4">
                        <div>{message}</div>
                    </div>
                    {
                        content &&
                        <div className="flex ">
                            <CSVLink className="btn !bg-gradient-to-br mr-3  from-[#3C3C3C] to-[#18181A] !text-white !p-3" data={content}>Download <FontAwesomeIcon icon={faFileCsv}/></CSVLink>
                            <a className="btn !bg-gradient-to-br  from-[#3C3C3C] to-[#18181A] !text-white !p-2.5" href="https://api.bierboek.stichtingkunstboek.com/zips/exported_files.zip"> Download <FontAwesomeIcon icon={faImages}/></a>
                        </div>
                    }
                </div>
            </div>
        </DefaultLayout>
    )
}

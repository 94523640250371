import Papa from 'papaparse';
import axios from 'axios';
import { API_URL } from '../config/api';

let lineNumber = 0;
let emailsWithPassword = [];

class ImportService {
    async importUsers(file, containsMail, exportFile) {
        const csvData = file.data;
        const errorsData = [];
        lineNumber = 0;

        const updatedCsvData = containsMail
            ? await this.registerUser(csvData, errorsData, 'mail', (line, password) =>
                this.createUser({ email: line.mail, password, nameBrouwer: line.Naam, gemeente: line.Gemeente }, line)
            )
            : await this.registerUser(csvData, errorsData, 'Username', (line, password) =>
                this.createUserWithUsername({ username: line.Username, password, nameBrouwer: line.Brouwerij, gemeente: line.Gemeente }, line)
            );

        if (exportFile) {
            this.downloadCSV(Papa.unparse(updatedCsvData), 'imported.csv');
            this.downloadCSV(Papa.unparse(errorsData), 'errors.csv');
        }
    }

    async registerUser(csvData, errorsData, key, createUserFn) {
        return await Promise.all(csvData.map(async (line) => {
            const password = generatePassword();
            const userExists = this.checkEmail(line[key]);

            if (!userExists) {
                try {
                    const user = await createUserFn(line, password);
                    if (user.status === 200) {
                        line.Wachtwoord = password;
                    } else {
                        this.handleError(errorsData, line, 'error', 'ongeldige ' + key);
                    }
                } catch (error) {
                    this.handleError(errorsData, line, 'error', 'ongeldige ' + key);
                }
            } else {
                this.handleError(errorsData, line, 'Wachtwoord', 'Dubbel ' + key, userExists.password);
            }

            return line;
        }));
    }

    checkEmail(email) {
        return emailsWithPassword.find(e => e.email === email);
    }

    async createUser(data, line) {
        console.log(API_URL)
        try {
            return await axios.post(API_URL + '/auth/signup', { ...data, email: line.mail, overwrite: true} );
        } catch (error) {
            window.error = error.response.data;
            console.error('email: ', line.mail, ' Error creating user:', error.response.data);
            return error;
        }
    }

    async createUserWithUsername(data, line) {
        try {
            return await axios.post(API_URL + '/auth/signup', data);
        } catch (error) {
            console.error('username: ', line.Username, ' Error creating user:', error.response.data);
            return error;
        }
    }

    handleError(errorsData, line, key, reason, value = '') {
        lineNumber++;
        line[key] = value;
        line.reden = reason;
        errorsData.push(line);
    }

    downloadCSV(csvString, fileName = 'export.csv') {
        const blob = new Blob([csvString]);
        console.log('gebruikers niet gevonden: ', lineNumber);
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
    }
}

const generatePassword = () => {
    let length = 10,
        charset = 'abcdefghijkmnopqrstuvwxyzABCDEFGHJKMNOPQRSTUVWXYZ0123456789*#',
        retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
};

export default new ImportService();

import React, {useState, useEffect} from "react"

import UserService from '../../services/user.service'
import AuthService from "../../services/auth.service";
//import { FaSearch } from 'react-icons/fa';
import {useNavigate} from "react-router-dom";
import DefaultLayout from "../../layout/defaultLayout";

export default function Users() {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const [state, setState] = useState(
        {
            nameBrouwer: '', name: '', email: '', website: '', gemeente: '',
            password: '', passwordAgain: '',
            terms: false, message: false, messageText: ''
        }
    )
    const [adduser, setAdduser] = useState(false)
    const [users, setUsers] = useState(null)
    const [someUsers, setSomeUsers] = useState(null)
    const [search, setSearch] = useState("")
    const [change, setChange] = useState("")
    const [curID, setCurID] = useState("")
    const resultsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);

    function validateEmail(email) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return (true)
        }
        return (false)
    }

    function handleSubmit() {
        if (!(!!state.email && !!state.password && !!state.passwordAgain)) {
            setState({...state, message: true, messageText: "gebruiker kon niet aangemaakt worden. Wil aub e-mail en wachtwoord ingeven"})
        } else {
            if (state.password === state.passwordAgain) {
                if (validateEmail(state.email)) {
                    AuthService.addUser(state.email, state.password, state.nameBrouwer, state.name,
                        state.website, state.gemeente).then(
                        (resp) => {
                            setState({
                                nameBrouwer: '', name: '', website: '', email: '', gemeente: '', password: '', passwordAgain: '',
                                terms: false, message: true, messageText: 'opgeslaan!'
                            })
                            setChange("new user!")
                        },
                        error => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            console.log(error.toString())
                            //setMessage(resMessage)
                        }
                    );
                } else {
                    setState({...state, message: true, messageText: "e-mailadres niet geldig"})
                }
            } else {
                setState({...state, message: true, messageText: "Wachtwoorden moeten hetzelfde zijn!"})
            }
        }
    }

    function handleAnull() {
        setState({
            nameBrouwer: '', name: '', website: '', email: '', gemeente: '', password: '', passwordAgain: '',
            terms: false, message: false, messageText: ''
        })
        setAdduser(false)
    }

    async function handleSearch() {
        const content = await UserService.findUsers(search.trim())
        setUsers(content.data)
    }

    useEffect(() => {
        if (!AuthService.getCurrentUser()) {
            navigate("/login")
        }

        async function fetchUsers() {
            const content = await UserService.getUsers()
            setUsers(content.data)
            if (content.data && content.data.length > resultsPerPage) {
                let cont = [...content.data]
                setSomeUsers(cont.splice(0, resultsPerPage))
            } else
                setSomeUsers(content.data)
        }

        fetchUsers()
    }, [change])

    function handleEdit(data) {
        console.log(data);
        navigate("/user-bewerkt", {
            state: {data},
        })
    }

    function handleDelete(id) {
        setCurID(id)
    }

    function confirmDelete() {
        async function deleteA() {
            const result = await UserService.deleteUser(curID)
        }

        deleteA()
        let ss = someUsers.filter(x => x._id !== curID)
        setSomeUsers(ss)
        setCurID("")
        setChange(ss)
    }

    async function blockUser(id) {
        await UserService.updateUser({_id: id, blocked: true})
        setChange("blocked!")
    }

    async function unblockUser(id) {
        await UserService.updateUser({_id: id, blocked: false})
        setChange("unblocked!")
    }


    function handlePage(e, index) {
        e.preventDefault()
        if (users) {
            let newUsers = [...users]
            newUsers = newUsers.splice(index * resultsPerPage, resultsPerPage)
            setSomeUsers(newUsers)
            setCurrentPage(index)
        }
    }

    const MAX_VISIBLE_PAGES = 5;

    const Pages = () => {
        const pageLinks = [];

        if (users && users.length) {
            const totalPages = Math.ceil(users.length / resultsPerPage);

            let startPage = Math.max(currentPage - Math.floor(MAX_VISIBLE_PAGES / 2), 0);
            let endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

            if (endPage - startPage + 1 < MAX_VISIBLE_PAGES) {
                // Adjust startPage when near the end to maintain the number of visible pages
                startPage = Math.max(endPage - MAX_VISIBLE_PAGES + 1, 0);
            }

            for (let i = startPage; i <= endPage; i++) {
                pageLinks.push(
                    <span key={i}>
          <a href="#" onClick={(e) => handlePage(e, i )}>
            {i + 1}
          </a>
                        {i < endPage && <span className="mr-1">,</span>}
        </span>
                );
            }

            if (startPage > 0) {
                pageLinks.unshift(<span key="ellipsis-start">...</span>);
            }

            if (endPage < totalPages - 1) {
                pageLinks.push(<span key="ellipsis-end">...</span>);
            }
        }

        return <div className="pagination">{pageLinks}</div>;
    };


    return (
        <DefaultLayout index="1">
            {curID &&
                <div id="myNav" className="absolute top-1/4 w-full ">
                    <div className=" bg-gradient-to-br text-center from-[#3C3C3C] to-[#18181A] w-fit ml-auto mr-auto flex align-items-start justify-content-center row">
                        <div className="flex-column p-5" style={{minWidth: "200px"}}>
                            <div className="flex justify-center font-extrabold text-3xl tex text-primary">Wissen?</div>
                            <div className="flex-row flex align-items-center text-white justify-around mt-3">
                                <button type="button" className="link-underline" onClick={() => confirmDelete()}>Ja</button>
                                <button type="button" className="link-underline" onClick={() => setCurID("")}>Nee</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="pagina-gebruikers d-flex flex-column mb-4">
                {
                    adduser ?
                        <>
                            <div className="font-montserrat font-bold text-3xl text-primary bg-black p-4  md:pl-6 2xl:pl-10">
                                <h2>
                                    Nieuwe gebruiker toevoegen
                                </h2>
                            </div>
                            <form className="mt-4 w-full p-4 md:p-6 2xl:p-10">
                                <div className="form-group flex-col ml-auto mr-auto ">
                                    <div className="flex flex-row justify-around">
                                        <div className="flex flex-col w-96">
                                            <label htmlFor="naambrouwer">Naam brouwer:</label>
                                            <input type="text" className="form-control input-field-light" id="naambrouwer" value={state.nameBrouwer} placeholder="brouwer"
                                                   onChange={e => setState({...state, nameBrouwer: e.target.value})}
                                            />
                                        </div>
                                        <div className=" flex flex-col w-96">
                                            <label htmlFor="voornaam">Website brouwerij:</label>
                                            <input type="text" className="form-control input-field-light" id="voornaam" value={state.website} placeholder="Peter"
                                                   onChange={e => setState({...state, website: e.target.value})}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-row mt-4 justify-around">
                                        <div className="flex flex-col w-96">
                                            <label htmlFor="woonplaats">Gemeente/stad brouwerij:</label>
                                            <input type="woonplaats" className="form-control input-field-light" id="woonplaats" value={state.gemeente} placeholder="Brugge"
                                                   onChange={e => setState({...state, gemeente: e.target.value})}/>
                                        </div>
                                        <div className="flex flex-col w-96">
                                        </div>
                                    </div>

                                    <div className="flex flex-row mt-4 justify-around">
                                        <div className=" flex flex-col w-96">
                                            <label htmlFor="voornaam">Naam Contactpersoon:</label>
                                            <input type="text" className="form-control input-field-light" id="voornaam" value={state.name} placeholder="Peter"
                                                   onChange={e => setState({...state, name: e.target.value})}
                                            />
                                        </div>
                                        <div className="flex flex-col w-96">
                                            <label htmlFor="email">E-mail:</label>
                                            <input type="email" className="form-control input-field-light" id="email" value={state.email} placeholder="peterdebakker@gmail.com"
                                                   onChange={e => setState({...state, email: e.target.value})}/>
                                        </div>
                                    </div>


                                    <div className="flex flex-row mt-4 justify-around">
                                        <div className="flex flex-col w-96">
                                            <label htmlFor="wachtwoord">Wachtwoord</label>
                                            <input type="text" className="form-control input-field-light" id="wachtwoord" value={state.password} placeholder="Wachtwoord"
                                                   onChange={e => setState({...state, password: e.target.value})}
                                            />
                                        </div>
                                        <div className="flex flex-col w-96">
                                            <label htmlFor="herhaal">Herhaal wachtwoord</label>
                                            <input type="text" className="form-control input-field-light" id="herhaal" value={state.passwordAgain} placeholder="Herhaal wachtwoord"
                                                   onChange={e => setState({...state, passwordAgain: e.target.value})}
                                            />
                                        </div>
                                    </div>

                                    {
                                        state.messageText &&
                                        <div className="row mt-4">
                                            <div>{state.messageText}</div>
                                        </div>
                                    }

                                    <div className="row mt-14">

                                        <div className="flex flex-row justify-between">
                                            <div>
                                                <button type="button" className="btn"
                                                        onClick={() => {
                                                            setAdduser(false);
                                                            setChange("back!")
                                                        }}
                                                >Terug
                                                </button>
                                            </div>
                                            <button type="button" className="btn"
                                                    onClick={handleSubmit}
                                            >+ Gebruiker toevoegen
                                            </button>
                                            <div>
                                                <button type="button" className="btn"
                                                        onClick={handleAnull}
                                                >x Annuleren
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </form>
                        </>
                        :
                        <>
                            <div className="font-montserrat font-bold text-3xl text-primary bg-black p-4  md:pl-6 2xl:pl-10 sticky top-0">
                                <h2>
                                    Gebruikers
                                </h2>
                            </div>

                            <div className="p-4 md:p-6 2xl:p-10">


                            <div className="mt-3 flex w-full justify-between">
                                    <div className="mr-3">
                                        <div className="search-container">
                                            <input type="text" className="input-field-light" value={search} onChange={e => setSearch(e.target.value)} placeholder="Search.." name="search"/>
                                            <button type="submit" onClick={() => handleSearch()}>{/*<FaSearch/>*/}</button>
                                        </div>
                                    </div>

                                    <button type="button" className="btn" onClick={() => {
                                        setAdduser(true);
                                        setState({...state, messageText: ''})
                                    }}>+ Gebruiker toevoegen
                                    </button>
                                </div>


                                <div className="pagina-window flex flex-col justify-center mt-4" style={{border: "none"}}>
                                    <table className="w-full mt-10">
                                        <thead className="text-xs text-gray-700 text-left  bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="pl-14 ">
                                                Naam Brouwer
                                            </th>
                                            <th scope="col" className="pl-14 ">
                                                Naam Contactpersoon
                                            </th>
                                            <th scope="col" className=" py-3">
                                                E-mail
                                            </th>
                                            <th scope="col" className=" py-3">
                                            </th>

                                        </tr>
                                        </thead>
                                        <tbody className={""}>

                                        {
                                            someUsers && someUsers.map((x, i) =>
                                                <tr key={i} className="odd:bg-white odd:dark:bg-gray-200 even:bg-gray-50 even:dark:bg-gray-50 border-b text-left ">
                                                    <th scope="row" className="  font-medium text-gray-900 whitespace-nowrap pl-14">
                                                        {/*<input type="checkbox" className="" id="exampleCheck1"/>*/}
                                                        {x.nameBrouwer}
                                                    </th>
                                                    <th scope="row" className="  font-medium text-gray-900 whitespace-nowrap pl-14">
                                                        {/*<input type="checkbox" className="" id="exampleCheck1"/>*/}
                                                        {x.name}
                                                    </th>

                                                    <td className="col-md-2">
                                                        {x.email}
                                                    </td>

                                                    <td className="flex items-center justify-between py-3">
                                                        <button type="button" className="btn " onClick={() => handleEdit({...x})}>Bewerken</button>
                                                        <button type="button" className="btn" onClick={() => handleDelete(x._id)}>Wissen</button>
                                                        {
                                                            x.blocked ?
                                                                <button type="button" className="btn" onClick={() => unblockUser(x._id)}>Deblokkeren</button>
                                                                :
                                                                <button type="button" className="btn" onClick={() => blockUser(x._id)}>Blokkeren</button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-3 flex flex-row justify-end">
                                    <Pages/>
                                </div>
                            </div>
                        </>
                }
            </div>
        </DefaultLayout>
    )
}

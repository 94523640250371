import React, {useEffect, useState} from "react"
import UserService from '../../services/user.service'

import DefaultLayout from "../../layout/defaultLayout";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {API_URL} from "../../config/api";
import i18n from "i18next";

export default function BeerBewerkt(props) {
    const navigate = useNavigate();
    const {t} = useTranslation();

    let currentLanguage = i18n.language;
    const {state} = useLocation();
    const [data, setData] = useState()
    const [fileError, setFileError] = useState(false);
    const [fileChanged, setFileChanged] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    useEffect(() => {
        setData(state.data)
        console.log(state.data)
    }, []);

    const [message, setMessage] = useState("")

    async function handleSubmit() {
        if (!fileChanged){
            delete data.label;
        }
        setIsUploading(true);

        const res = await UserService.updateBeer(data)
        // setState(initialState)
        setIsUploading(false);
        if (res.status === 200 )   {
            setMessage("bijgewerkt!")
            setTimeout(() => {handleBack();}, 1000)
        } else{
            setMessage("Er is een fout opgetreden, probeer later opnieuw")
        }
    }

    function handleBack() {
        navigate("/beers")
    }

    function checkFileSize(e) {
        setFileError(false);
        let maxSize = 5000000;
        let input = e.target;
        console.log(input);
        if (input.files.length > 0) {
            let fileSize = input.files[0].size;
            if (fileSize > maxSize) {
                e.target.file = null;
                input.value = '';
                setFileError(true);
                return;
            }
        }
        setFileChanged(true);
        setData({...data, label: input.files[0]})
    }

    function scrollUp() {
        if (window)
            window.scroll({top: 0, left: 0, behavior: 'smooth'})
    }

    return (
        <DefaultLayout>
            <div className="pagina-gebruikers d-flex flex-column mb-4">

                <div className="font-montserrat font-bold text-3xl text-primary bg-black p-4  md:pl-6 2xl:pl-10">
                    <h2>
                        {t("Edit_beer")}
                    </h2>
                </div>
                {data &&
                    <div className="row mt-4 p-4 md:p-6 2xl:p-10">
                        <div className="col">
                            <div className="flex flex-col w-96 mt-3">
                                <label htmlFor="naambier">{t("Name_beer")}:</label>
                                <input type="text" className="input-field-light" id="naambier" placeholder=""
                                       value={data.name}
                                       onChange={e => setData({...data, name: e.currentTarget.value})}
                                />
                            </div>

                            <div className="flex flex-col w-96 mt-3">
                                <label htmlFor="naambrouwer">{t("Name_brewer")}:</label>
                                <input type="text" className="input-field-light" id="naambrouwer" placeholder=""
                                       value={data.nameBrouwer}
                                       onChange={e => setData({...data, nameBrouwer: e.currentTarget.value})}
                                />
                            </div>

                            <div className="flex flex-col w-96 mt-3">
                                <label htmlFor="gistingtekstNL">{t("Fermentation_process")}:</label>
                                <input maxLength={60} type="text" className="input-field-light" id="gistingtekstNL" placeholder={t("Fermentation_process_example")}
                                       value={data["gisting_"+currentLanguage]}
                                       onChange={e => setData({...data,  [`gisting_${currentLanguage}`]  : e.currentTarget.value})}
                                />
                            </div>


                            <div className="flex flex-col w-96 mt-3">
                                <label htmlFor="stijltekstNL">{t("Style")}::</label>
                                <input maxLength={22}  type="text" className="input-field-light" id="stijltekstNL" placeholder={t("Style_example")}
                                       value={data["stijl_"+currentLanguage]}
                                       onChange={e => setData({...data, ["stijl_"+currentLanguage]: e.currentTarget.value})}
                                />
                            </div>


                            <div className="flex flex-col w-96 mt-3">
                                <label htmlFor="samenstellingtekstNL">{t("Ingredients")}:</label>
                                <input maxLength={60} type="text" className="input-field-light" id="samenstellingtekstNL" placeholder=""
                                       value={data["samenstelling_"+currentLanguage]}
                                       onChange={e => setData({...data, ["samenstelling_"+currentLanguage]: e.currentTarget.value})}
                                />
                            </div>

                            <div className="flex flex-col w-96 mt-3">
                                <label htmlFor="alcoholpercentage">{t("Alcoholpercentage")}:</label>
                                <input maxLength={10} type="text" className="input-field-light" id="alcoholpercentage" placeholder=""
                                       value={data.alcoholpercentage}
                                       onChange={e => setData({...data, alcoholpercentage: e.currentTarget.value})}
                                />
                            </div>

                            <div className="flex flex-col w-96 mt-3">
                                <label htmlFor="kleurNL">{t("Color_clarity")}:</label>
                                <input maxLength={22} type="text" className="input-field-light" id="kleurNL" placeholder=""
                                       value={data["kleur_"+currentLanguage]}
                                       onChange={e => setData({...data, ["kleur_"+currentLanguage]: e.currentTarget.value})}
                                />
                            </div>

                            <div className="flex flex-col w-96 mt-3">
                                <label htmlFor="smaaktekstNL">{t("Character_taste_aroma")}:</label>
                                <input maxLength={180} type="text" className="input-field-light" id="smaaktekstNL" placeholder=""
                                       value={data["smaak_"+currentLanguage]}
                                       onChange={e => setData({...data, ["smaak_"+currentLanguage]: e.currentTarget.value})}
                                />
                            </div>

                            {(data.label &&  !fileChanged && !data.label.includes("undefined") ) &&  (
                                <div className="mt-3">
                                    <img src={ data.label} alt="label" className=" h-32"/>
                                </div>
                            )}

                                <div className="flex flex-col w-96 mt-3">
                                <label htmlFor="label">{t("Label")} (max 5Mb):
                        </label>
                        <input type="file" className="mt-3" id="label" accept="image/*" size="5000000"
                               onChange={e => checkFileSize(e)}
                        />
                        {fileError && <p className="text-red-500">{t("Max_file_size")}</p>}
                    </div>


                {message &&
                    <div className="mt-4 text-red-500">
                        {message}
                    </div>
                }

                <div className="mt-4 w-50 flex justify-content-between">
                    <div>
                        <button onClick={() => handleBack()} type="button" className="btn ml-3">{t("Back")}</button>
                    </div>
                    <div>
                        <button type="button" className="btn ml-3"
                                onClick={handleSubmit}>{t("Update")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
}
</div>
</DefaultLayout>
)
}
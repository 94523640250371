import axios from "axios";
//import AsyncStorage from '@react-native-async-storage/async-storage';
//might need autolinking on darwin 
//https://react-native-async-storage.github.io/async-storage/docs/install/

const config = require("../config/api");

const API_URL = `${config.API_URL}/auth/`;

class AuthService {
  login(username, password) {
    let body = {}

    const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(!emailRegexp.test(username)){
      console.log("here")
      body.username = username;
      body.password = password;
    } else{
      body.email = username;
      body.password = password;
    }
    return axios
      .post(API_URL + "signin", body)
      .then((response) => {
        if (response.data.accessToken && (typeof window !== 'undefined')) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
  }

  logout() {
    if(typeof window !== 'undefined')
      localStorage.removeItem("user");
  }

  register(email, password, firstName, lastName, birthday, gender, gementee, postcode, partij,
    onderwijsniveau, beroep
    ) {
    return axios.post(API_URL + "signup", {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      birthday: birthday,
      gender: gender, 
      gementee: gementee,
      postcode: postcode,
      partij: partij, 
      onderwijsniveau: onderwijsniveau,
      beroep: beroep
    }).then((response) => {
        if (response && (typeof window !== 'undefined')) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        //return response.data;
      });
  }

  addUser(email, password, nameBrouwer, name, website, gemeente) {
    console.log({
      email: email,
      password: password,
      nameBrouwer: nameBrouwer,
      name: name,
      gemeente: website,
      website: gemeente,
    })
    return axios.post(API_URL + "signup", {
      email: email,
      password: password,
      nameBrouwer: nameBrouwer,
      name: name,
      gemeente: gemeente,
      website: website,
    }).then((response) => {
        if (response) {
          //localStorage.setItem("user", JSON.stringify(response.data));
        }
      });
  }

  getCurrentUser() {
    return typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : null;
  }

  setCurrentUser(data) {
    return typeof window !== 'undefined' ? JSON.parse(localStorage.setItem('user', JSON.stringify(data))) : null;
  }

  getVerificationEmail(userId) {
    return axios.get(API_URL + 'verification/get-activation-email', {params: {
      userId: userId
    }});
  }

  async isAdmin(userId){
    return await axios.post(API_URL + 'is-admin', {
      userId: userId
    });
  }

  checkStatus(userId) {
    return axios.get(API_URL + 'verification/status', {params: {
      userId: userId
    }});
  }

  sendPasswordResetEmail(userEmail) {
    return axios.post(API_URL + 'password-forgot', {
      userEmail: userEmail
    });
  }
}

export default new AuthService();